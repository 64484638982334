import React, {useState} from "react"
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
} from '@mui/material';

import { doPost } from "../../../actions/_methods";
import { API_FORGOT_PASSWORD } from "../../../const/api_paths";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '10px',
    width: '810px',
  },
  buttons_section: {
    marginTop: '20px',
  },
}));


export default ({modeSwither}) => {
  const classes = useStyles();

  const [email, setEmail] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = () => {
    doPost(`${API_FORGOT_PASSWORD}`, { email: email }, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onSuccess = (res) => {
    alert(res['alerts'], { variant: 'success' });
    const windowGlobal = typeof window !== 'undefined' && window
    windowGlobal.location = "/"
  }

  const onError = (res) => {
    enqueueSnackbar(res['errors'], { variant: 'error' });
  }

  const emailForm = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="forgot_password_email"
      label="Email Address"
      name="email"
      autoFocus
      variant="outlined"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSubmit()
        }
      }}
    />
  )

  const sendLinkButton = (
    <Button
      fullWidth
      variant="contained"
      size="large"
      color="primary"
      onClick={handleSubmit}
    >
      Send Forgot Password Email
    </Button>
  )

  return(
    <Card className={classes.box}>
      <CardHeader title="Forgot Password" />
      <CardContent>
        <Box>{emailForm}</Box>
        <Box className={classes.buttons_section}>{sendLinkButton}</Box>
        <Box className={classes.buttons_section}>{modeSwither}</Box>
      </CardContent>
    </Card>
  )
}