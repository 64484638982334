import React, {useState} from "react"
import Signin from "./components/Signin"
import Signup from "./components/Signup"
import ForgotPassword from "./components/ForgotPassword"
import ModeSwitcher from "./components/_auth_mode_switcher"

export default () => {
  const [auth_mode, setAuthMode] = useState('signin')

  const onChangeAuthMode = (target_auth_mode) => {
    setAuthMode(target_auth_mode)
  }

  const modeSwitcher = <ModeSwitcher
    auth_mode={auth_mode}
    onChangeAuthMode={onChangeAuthMode}
  />

  return (
    <div style={{margin: '50px auto'}}>
      {auth_mode === 'signin' && <Signin modeSwither={modeSwitcher} />}
      {auth_mode === 'signup' && <Signup modeSwither={modeSwitcher} />}
      {auth_mode === 'forgot_password' && <ForgotPassword modeSwither={modeSwitcher} />}
    </div>
  )
}
