import React from "react"
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  links: {
    color: '#1976d2',
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
    }
  }
}));

export default ({auth_mode, onChangeAuthMode}) => {
  const classes = useStyles();

  const signInLink = () => {
    if(auth_mode !== 'signin') {
      return(
        <div>
          <a className={classes.links} href="javascript:void(0)" onClick={() => onChangeAuthMode('signin')}>Sign in here</a>
        </div>
      )
    }
  }

  const signUpLink = () => {
    if(auth_mode !== 'signup') {
      return(
        <div>
          <a className={classes.links} href="javascript:void(0)" onClick={() => onChangeAuthMode('signup')}>Signup / Register</a>
        </div>
      )
    }
  }

  const forgotPasswordLink = () => {
    if(auth_mode !== 'forgot_password') {
      return(
        <div>
          <a className={classes.links} href="javascript:void(0)" onClick={() => onChangeAuthMode('forgot_password')}>Forgot Password</a>
        </div>
      )
    }
  }

  return(
    <>
      {signInLink()}
      {signUpLink()}
      {forgotPasswordLink()}
    </>
  )
}