import React from 'react';
import Container        from '@mui/material/Container';
import { makeStyles }   from '@mui/styles';
import { LAYOUT_STYLES } from './components/styles'
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles((theme) => (LAYOUT_STYLES(theme)));

export const LayoutLogout = ({children}) => {
  const classes = useStyles();

  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>
        <main className={classes.content}>
          <Container className={classes.container}>
            {children}
          </Container>
        </main>
      </div>
    </SnackbarProvider>
  );
}

export default LayoutLogout