import React, {useState} from "react"
import { signup } from '../actions/AuthAction'
import { setAuthToken } from '../../../actions/_auth';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '10px',
    width: '810px',
  },
  buttons_section: {
    marginTop: '20px',
  },
}));

export default ({modeSwither}) => {
  const classes = useStyles();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [retype_password, setRetypePassword] = useState('')
  const [error_message, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onClick = () => {
    setSubmitting(true)
    setErrorMessage('')
    signup({email, password}, {
      success: onSuccess,
      error: onError
    })
  }

  const onSuccess = (res) => {
    setSubmitting(false)
    setAuthToken(res.token)
    const windowGlobal = typeof window !== 'undefined' && window
    windowGlobal.location = "/"
  }

  const onError = (message) => {
    setSubmitting(false)
    setErrorMessage(message)
  }

  const emailForm = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="signup_email"
      label="Email Address"
      name="email"
      autoFocus
      variant="outlined"
      disabled={submitting}
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onClick()
        }
      }}
    />
  )

  const passwordForm = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="signup_password"
      label="Password"
      name="signup_password"
      variant="outlined"
      type="password"
      disabled={submitting}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onClick()
        }
      }}
    />
  )

  const retypePasswordForm = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="retype_password"
      label="Retype Password"
      name="retype_password"
      variant="outlined"
      type="password"
      disabled={submitting}
      value={retype_password}
      onChange={(e) => setRetypePassword(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onClick()
        }
      }}
    />
  )

  const signupButton = (
    <Button
      fullWidth
      variant="contained"
      size="large"
      color="primary"
      disabled={submitting}
      onClick={onClick}
    >
      Sign Up
    </Button>
  )

  const errorMessage = () => {
    if(error_message && error_message !== '') {
      return(
        <Alert severity="error">{error_message}</Alert>
      )
    }
  }

  return(
    <Card className={classes.box}>
      <CardHeader title="Sign Up" />
      <CardContent>
        {errorMessage()}
        <Box>{emailForm}</Box>
        <Box>{passwordForm}</Box>
        <Box>{retypePasswordForm}</Box>
        <Box className={classes.buttons_section}>{signupButton}</Box>
        <Box className={classes.buttons_section}>{modeSwither}</Box>
      </CardContent>
    </Card>
  )
}