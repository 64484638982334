import React, {useState} from "react"
import { signin } from '../actions/AuthAction'
import { setAuthToken } from '../../../actions/_auth'
import { setProfile } from '../../../actions/_profile'
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '10px',
    width: '810px',
  },
  buttons_section: {
    marginTop: '20px',
  },
}));

export default ({modeSwither}) => {
  const classes = useStyles();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error_message, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onClick = () => {
    setSubmitting(true)
    setErrorMessage('')
    signin({email, password}, {
      success: onSuccess,
      error: onError
    })
  }

  const onSuccess = (res) => {
    setSubmitting(false)

    var token = captureBearerToken(res.headers)
    if(token) {
      setAuthToken(token)
      setProfile(res.profile)
      const windowGlobal        = typeof window !== 'undefined' && window
      windowGlobal.location     = "/app/all"
    } else {
      setErrorMessage('Please Try Again. Failed to get token')
    }
  }

  const onError = (message) => {
    setSubmitting(false)
    setErrorMessage(message)
  }

  const captureBearerToken = (headers) => {
    var token = null
    headers.forEach((val, key) => {
      if(key === 'authorization') {
        token = val.split(' ')[1]
      }
    })
    return token
  }

  const emailForm = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="signin_email"
      label="Email Address"
      name="email"
      autoComplete="email"
      autoFocus
      variant="outlined"
      disabled={submitting}
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onClick()
        }
      }}
    />
  )

  const passwordForm = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="signin_password"
      label="Password"
      name="signin_password"
      variant="outlined"
      type="password"
      disabled={submitting}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onClick()
        }
      }}
    />
  )

  const signinButton = (
    <Button
      fullWidth
      variant="contained"
      size="large"
      color="primary"
      disabled={submitting}
      onClick={onClick}
    >
      Sign In
    </Button>
  )

  const errorMessage = () => {
    if(error_message && error_message !== '') {
      return(
        <Alert severity="error">{error_message}</Alert>
      )
    }
  }

  return(
    <Card className={classes.box}>
      <CardHeader title="Sign in" />
      <CardContent>
        {errorMessage()}
        <Box>{emailForm}</Box>
        <Box>{passwordForm}</Box>
        <Box className={classes.buttons_section}>{signinButton}</Box>
        <Box className={classes.buttons_section}>{modeSwither}</Box>
      </CardContent>
    </Card>
  )
}