const KEY = 'billebotProfile'
const localStorage = typeof window !== 'undefined' && window.localStorage

export const setProfile = (profile) => localStorage && localStorage.setItem(KEY, JSON.stringify(profile))

export const removeProfile = () => localStorage && localStorage.removeItem(KEY)

export const getProfile = () => localStorage && JSON.parse(localStorage.getItem(KEY))

export const hasProfile = () => Boolean(getProfile())
